import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';

import { AuthService } from '@ideals/core/auth';
import { FeatureFlagsService } from '@ideals/core/feature-flags';
import { createHttpParams } from '@ideals/utils/create-http-params';
import { getUserDisplayName } from '@ideals/utils/get-user-display-name';
import { sortItems } from '@ideals/utils/sort-items';

import {
  AccountAccessControlInfo,
  BusinessDevelopmentManager,
  ContractExpirationInfo,
  CorporateAccount,
  CorporateAccountAdministrator2,
  CorporateAccountManager,
  CorporateAccountPlanRequestPlaces,
  CorporateAccountsFilters,
  CorporateAccountStatusChangeReason,
  CorporateAccountStatusId,
  CorporateAccountSubscriptionPlan,
  CorporateAccountsUsage,
  CorporateAccountUser,
  Country,
  DetailedCorporateAccount,
  DetailedCorporateAccountAdministrator,
  DetailedRegularCorporateAccount,
  ListCorporateAccount,
  ProjectServer,
  SubscriptionPlanId,
  UserAccount,
  UserAccounts,
} from '../../models';

interface CorporateAccountDto extends Omit<CorporateAccount, 'creationDate'> {
  readonly creationDate: string;
}

interface CreateAccountDto {
  readonly accountManagerId: number;
  readonly accountName: string;
  readonly accountUsers: CorporateAccountUser[];
  readonly administrators: CorporateAccountAdministratorDto[] | null;
  readonly contractNumber: string | null;
  readonly countryId: number;
  readonly creationDate: string;
  readonly forbidCreateTrialAccount: boolean;
  readonly newIdealsSwitchEnabled: boolean;
  readonly subscriptionPlanId: SubscriptionPlanId;
  readonly trialAutoClosureEnabled: boolean;
}

interface BillingSubscriptionAccessDto {
  readonly hasActiveSubscription: boolean;
  readonly hasSubscriptionToReview: boolean;
  readonly showSubscriptionBuilder: boolean;
}

interface ContractExpirationInfoDto {
  readonly accountManagerEmail: string | null;
  readonly expiryDate: string | null;
}

export interface DetailedCorporateAccountDto extends CorporateAccountDto, Omit<
DetailedCorporateAccount,
'administrators' |
'closureDate' |
'creationDate' |
'trialExpirationDate' |
'newIdealsSwitch'
> {
  readonly administrators: DetailedCorporateAccountAdministratorDto[];
  readonly closureDate: string | null;
  readonly newIdealsSwitchEnabled: boolean;
  readonly trialExpirationDate: string | null;
}

interface ListCorporateAccountDto {
  readonly accountManager: CorporateAccountManager;
  readonly accountName: string;
  readonly administrators: string[];
  readonly closureDate: string;
  readonly contractNumber: string;
  readonly country: Country | null;
  readonly creationDate: string;
  readonly humanReadableId: string;
  readonly id: string;
  readonly numberOfActiveRooms: number;
  readonly numberOfRooms: number;
  readonly status: CorporateAccountStatusId;
  readonly subscriptionPlan: CorporateAccountSubscriptionPlan;
  readonly trialExpirationDate: string;
}

export interface DetailedRegularCorporateAccountDto {
  readonly accountManager: CorporateAccountManager;
  readonly accountName: string;
  readonly businessDevelopmentManager: BusinessDevelopmentManager | null;
  readonly closureDate: string | null;
  readonly creationDate: string;
  readonly humanReadableId: string;
  readonly status: CorporateAccountStatusId;
  readonly trialExpirationDate: string | null;
}

interface EditAccountDto extends CreateAccountDto {
  readonly businessDevelopmentManagerId: number | null;
  readonly showBillingInformation: boolean;
  readonly showOutstandingBalance: boolean;
  readonly status: CorporateAccountStatusId;
  readonly statusChangeReason: CorporateAccountStatusChangeReason | null;
  readonly trialExpirationDate: string | null;
  readonly trialProlongationReason: string | null;
}

interface CorporateAccountAdministratorDto {
  readonly canCreateRooms: boolean;
  readonly canManageAdministrators: boolean;
  readonly email: string;
}

interface UserAccountDto {
  readonly accountName: string;
  readonly allowedHosts: ProjectServer[];
  readonly id: string;
  readonly subscriptionPlanId: SubscriptionPlanId;
}

export interface CorporateAccountMatching {
  readonly humanReadableId: string;
  readonly id: string;
  readonly name: string;
  readonly status: CorporateAccountStatusId;
  readonly subscriptionPlan: CorporateAccountSubscriptionPlan;
}

type DetailedCorporateAccountAdministratorDto = Omit<DetailedCorporateAccountAdministrator, 'name'> & {
  readonly firstName: string;
  readonly lastName: string;
};

export const PAGE_SIZE = 30;

function mapCorporateAccount({ creationDate, ...rest }: CorporateAccountDto): CorporateAccount {
  return { ...rest, creationDate: new Date(creationDate) };
}

function mapCorporateAccountAdministrator(
  { permissions, ...rest }: DetailedCorporateAccountAdministrator
): CorporateAccountAdministratorDto {
  return { ...rest, ...permissions };
}

function mapCorporateAccountAdministratorDto(
  dto: DetailedCorporateAccountAdministratorDto
): DetailedCorporateAccountAdministrator {
  return {
    ...dto,
    name: getUserDisplayName(dto),
    lastLogin: dto.lastLogin ? new Date(dto.lastLogin) : null,
  };
}

function mapCorporateAccountAdministrators(
  admins: DetailedCorporateAccountAdministratorDto[]
): DetailedCorporateAccountAdministrator[] {
  return admins?.map((admin) => mapCorporateAccountAdministratorDto(admin));
}

export function mapCreateAccount(account: DetailedCorporateAccount): CreateAccountDto {
  return {
    accountUsers: account.accountUsers ?? [],
    accountManagerId: account.accountManager.id,
    accountName: account.accountName,
    administrators: account.administrators ? account.administrators.map((admin) => mapCorporateAccountAdministrator(admin)) : null,
    contractNumber: account.contractNumber,
    countryId: account.country!.id,
    creationDate: account.creationDate.toISOString(),
    forbidCreateTrialAccount: account.forbidCreateTrialAccount,
    newIdealsSwitchEnabled: account.newIdealsSwitch,
    subscriptionPlanId: account.subscriptionPlan.id,
    trialAutoClosureEnabled: account.trialAutoClosureEnabled,
  };
}

export function mapDetailedCorporateAccount({
  administrators,
  closureDate,
  trialExpirationDate,
  ...rest
}: DetailedCorporateAccountDto): DetailedCorporateAccount {
  return {
    ...rest,
    ...mapCorporateAccount(rest),
    administrators: mapCorporateAccountAdministrators(administrators),
    closureDate: closureDate ? new Date(closureDate) : null,
    trialExpirationDate: trialExpirationDate ? new Date(trialExpirationDate) : null,
    newIdealsSwitch: rest.newIdealsSwitchEnabled,
  };
};

export function mapDetailedRegularCorporateAccount(
  regularAccountDetails: DetailedRegularCorporateAccountDto
): DetailedRegularCorporateAccount {
  return {
    ...regularAccountDetails,
    closureDate: regularAccountDetails.closureDate ? new Date(regularAccountDetails.closureDate) : null,
    creationDate: new Date(regularAccountDetails.creationDate),
    trialExpirationDate: regularAccountDetails.trialExpirationDate ? new Date(regularAccountDetails.trialExpirationDate) : null,
  };
}

export function mapEditAccount(account: DetailedCorporateAccount): EditAccountDto {
  return {
    ...mapCreateAccount(account),
    businessDevelopmentManagerId: account.businessDevelopmentManager?.id ?? null,
    showBillingInformation: account.showBillingInformation,
    showOutstandingBalance: account.showOutstandingBalance,
    status: account.status,
    statusChangeReason: account.statusChangeReason,
    trialExpirationDate: account.trialExpirationDate ? account.trialExpirationDate.toISOString() : null,
    trialProlongationReason: account.trialProlongationReason,
  };
};

function mapUserAccount(dto: UserAccountDto): UserAccount {
  return {
    allowedHosts: dto.allowedHosts.length ? dto.allowedHosts : undefined,
    id: dto.id,
    name: dto.accountName,
    subscriptionPlanId: dto.subscriptionPlanId,
  };
}

@Injectable({ providedIn: 'root' })
export class CorporateAccountService {
  readonly #authService = inject(AuthService);
  readonly #featureFlagsService = inject(FeatureFlagsService);
  readonly #httpClient = inject(HttpClient);

  checkOutstandingBalance(): Observable<boolean> {
    return this.#httpClient.get<boolean>('/api/ca/account/outstanding-balance')
      .pipe(catchError(() => of(false)));
  }

  createAccount(account: DetailedCorporateAccount, checkSsoUsers: boolean): Observable<string> {
    const params = new HttpParams().set('checkSsoUsers', checkSsoUsers);

    return this.#httpClient.post<string>('/api/ca/fca/account', mapCreateAccount(account), { params });
  }

  editAccount(account: DetailedCorporateAccount): Observable<DetailedCorporateAccount> {
    return this.#httpClient.put<DetailedCorporateAccount>(`/api/ca/fca/${account.id}/account`, mapEditAccount(account));
  }

  loadAccount(id: string): Observable<CorporateAccount> {
    return forkJoin([
      this.#httpClient.get<CorporateAccountDto>(`/api/ca/fca/${id}/account`),
      this.#featureFlagsService.isEnabled$('fvdr-contract-builder', this.#authService.user.email)
        .pipe(switchMap((enabled) => enabled
          ? this.#httpClient.get<BillingSubscriptionAccessDto>(`/api/public/payment-service/subscriptions/ca/${id}/access-control-info`)
          : of(undefined))),
    ])
      .pipe(
        map(([account, billingSubscriptionAccess]) => {
          const { hasActiveSubscription, hasSubscriptionToReview, showSubscriptionBuilder } = billingSubscriptionAccess ?? {};

          return mapCorporateAccount(
            {
              ...account,
              hasActiveSubscription,
              hasSubscriptionToReview,
              showBillingSubscriptionTab: !!hasActiveSubscription || !!hasSubscriptionToReview || !!showSubscriptionBuilder,
            }
          );
        })
      );
  }

  loadAccountAccessControlInfo(accountId: string): Observable<AccountAccessControlInfo> {
    return this.#httpClient.get<CorporateAccountAdministrator2>(`/api/ca/accounts/${accountId}/account-user`)
      .pipe(
        map(({ accountAccessControlInfo }) => accountAccessControlInfo)
      );
  }

  loadAccounts(filters: CorporateAccountsFilters): Observable<ListCorporateAccount[]> {
    const filtrationParams = this.#getFiltrationParams(filters);

    return this.#httpClient.get<{ accounts: ListCorporateAccountDto[]; }>(
      '/api/ca/accounts/search',
      {
        params: {
          'accountRequest.pageSize': PAGE_SIZE,
          ...filtrationParams,
        },
      }
    ).pipe(map((response) => this.#mapCorporateAccounts(response.accounts)));
  }

  loadAccountsMatching(query: string, accountId: string): Observable<CorporateAccountMatching[]> {
    return this.#httpClient.get<CorporateAccountMatching[]>(
      `/api/ca/accounts/${accountId}/search/matching`,
      { params: { query } }
    );
  }

  loadAccountsUsage(accountsIds: string[]): Observable<CorporateAccountsUsage[]> {
    return this.#httpClient.post<CorporateAccountsUsage[]>(
      '/api/ca/accounts/search/usages',
      accountsIds
    );
  }

  loadAdministratedAccounts(requestParams: {
    readonly onlyFullAdmins?: boolean;
    readonly statuses: CorporateAccountStatusId[];
    readonly verified?: boolean;
  }): Observable<UserAccounts> {
    const params = createHttpParams(requestParams);

    return this.#httpClient.get<{
      administrated: UserAccountDto[];
      forbidCreateTrialAccount: boolean;
      participated: UserAccountDto[];
    }>('/api/ca/accounts', { params }).pipe(
      map((accountDtos) => {
        return {
          administrated: sortItems(
            accountDtos.administrated.map((dto) => mapUserAccount(dto)),
            [{ field: 'name', order: 1 }]
          ),
          participated: accountDtos.participated.map((dto) => mapUserAccount(dto)),
          forbidCreateTrialAccount: accountDtos.forbidCreateTrialAccount,
        };
      })
    );
  }

  loadContractExpirationInfo(): Observable<ContractExpirationInfo | undefined> {
    return this.#httpClient.get<ContractExpirationInfoDto>('/api/ca/account/expiry-stage')
      .pipe(
        map(({ accountManagerEmail, expiryDate }) => accountManagerEmail && expiryDate
          ? { accountManagerEmail, expiryDate: new Date(expiryDate) }
          : undefined),
        catchError(() => of(undefined))
      );
  }

  loadMaxTrialExpirationDays(): Observable<number> {
    return this.#httpClient.get<{ content: { maxTrialExpirationDays: number; }; }>('/api/ca/config/validation')
      .pipe(
        map(({ content: { maxTrialExpirationDays } }) => maxTrialExpirationDays)
      );
  }

  loadPermissions(accountId?: string): Observable<string[]> {
    const params = createHttpParams(accountId ? { accountId } : {});

    return this.#httpClient.get<string[]>('/api/ca/accounts/permissions', { params });
  }

  requestPlanUpgrade(accountId: string, planRequestPlace: CorporateAccountPlanRequestPlaces): Observable<void> {
    const params = createHttpParams({ planRequestPlace });

    return this.#httpClient.get<void>(`/api/ca/fca/${accountId}/request-plan-upgrade`, { params });
  }

  #getFiltrationParams(filters: CorporateAccountsFilters): object {
    const entries = Object.entries(filters)
      .filter(([, value]) => !!value)
      .map(([key, value]: [string, unknown]): [string, unknown] => {
        const entryValue = value instanceof Date ? value.toISOString() : value;

        return [`accountRequest.${key}`, entryValue];
      });

    return Object.fromEntries(entries) as object;
  }

  #mapCorporateAccounts(accounts: ListCorporateAccountDto[]): ListCorporateAccount[] {
    return accounts.map((account) => ({
      ...account,
      activeProjectsCount: account.numberOfActiveRooms,
      creationDate: new Date(account.creationDate),
      closureDate: account.closureDate ? new Date(account.closureDate) : null,
      projectsCount: account.numberOfRooms,
      trialExpirationDate: account.trialExpirationDate ? new Date(account.trialExpirationDate) : null,
      projectsAdmins: [],
      projectsUsers: 0,
      usedStorage: 0,
    }));
  }
}
