export type AccountPermissions = 'EditSupportAccess' | 'InviteAdministrators';

export type InternalRole = 'Support' | 'Sales' | 'ArManager' | 'Supervisor';
export type AdministratorRole = 'AccountManager' | 'Owner' | 'ProjectCoordinator';

export type RolePermissions = 'AccountAccess' |
'AccountInternalUpdate' |
'AccountNameUpdate' |
'AccountRead' |
'AccountUsageNotification' |
'AccountsSearch' |
'AuditRead' |
'BusinessDevelopmentManagerAssigning' |
'CloseRoom' |
'CreateAccount' |
'CreateProjects' |
'DeleteOwner' |
'DeleteLastAdmin' |
'EditDefaultRoomSettings' |
'EditSupportAccess' |
'InviteAdministrators' |
'ManageAccountOwner' |
'ManageAccountUsers' |
'MatchProjects' |
'Participants' |
'ReadDictionaries' |
'RequestPlanUpgrade' |
'RoomToggles' |
'ShowInternalVdrEditLinks' |
'TrialUpdate' |
'UpdateAccount' |
'UpdateRoomStatus' |
'ValidatePendingUpdate' |
'ViewAccountContractBuilder';

export interface InternalAccessControlInfo {
  readonly accountPermissions: AccountPermissions[];
  readonly isInternalRole: true;
  readonly role: InternalRole;
  readonly rolePermissions: RolePermissions[];
}

export interface AdministratorAccessControlInfo {
  readonly accountPermissions: AccountPermissions[];
  readonly isInternalRole: false;
  readonly role: AdministratorRole;
  readonly rolePermissions: RolePermissions[];
}

export type AccountAccessControlInfo = InternalAccessControlInfo | AdministratorAccessControlInfo;

export const OWNER_ROLE: AdministratorAccessControlInfo = {
  accountPermissions: ['InviteAdministrators', 'EditSupportAccess'],
  isInternalRole: false,
  role: 'Owner',
  rolePermissions: [],
};

export const ACCOUNT_MANAGER_ROLE: AdministratorAccessControlInfo = {
  accountPermissions: [],
  isInternalRole: false,
  role: 'AccountManager',
  rolePermissions: [],
};

export const PROJECT_COORDINATOR_ROLE: AdministratorAccessControlInfo = {
  accountPermissions: [],
  isInternalRole: false,
  role: 'ProjectCoordinator',
  rolePermissions: [],
};
